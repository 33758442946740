<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="api"/>
        <v-skeleton-loader type="table" ref="skeleton" v-if="api.isLoading">

        </v-skeleton-loader>
        <v-row class="mt-3" v-else>
            <v-col align="center" justify="center" cols="12" sm="7" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="pa-3" elevation-1>
                    <v-toolbar color="secondary" class="white--text mb-4">
                        <v-toolbar-title>
                            Whatsapp
                        </v-toolbar-title>
                      
                    </v-toolbar>
                    <AError :api="this.api"/>
                   
                    <!--BOC : Create a form here-->
                    <v-container>
                        <div class="d-flex flex-column">
                            <v-text-field v-model="company" dense outlined disabled filled label="Company Name">
                            </v-text-field>

                            <v-autocomplete item-text="name" return-object dense outlined :items="clientOptions" v-model="clientSelected" label="User">
                                <template v-slot:item="{item}">
                                    {{ item.name }} ( {{ item.mobile }} )
                                </template>
                            </v-autocomplete>

                            <v-autocomplete 
                            @change="updateWhatssapTemplate"
                            item-text="name"
                            return-object
                            :items="whatssapTemplateOptions" v-model="whatssapTemplateSelected" label="Template" dense outlined>
                            </v-autocomplete>

                            

                            <v-textarea 
                            ref="message"
                            label="Message"
                            :counter="2000"
                            :rules="rulesMaxLength"
                            filled solo dense v-model="whatssapTemplate">

                            </v-textarea>
                        </div>
                        <div class="d-flex justify-end">
                            <v-btn color="primary" plain @click="redirectBack">
                                Cancel
                            </v-btn>
                            <v-btn color="primary" @click="sendWhatssap">
                                Submit
                            </v-btn>
                        </div>
                       
                    </v-container>
                    <!--EOC-->
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import AError from '../../components/common/AError.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    AConfirmation,
    AError,
    ASuccessFour
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        rulesMaxLength:[v=>v.length<2000||'Max 2000 characters'],
        isPending:false,
        rules:[
            value=>value[0].size < 10000000 || 'File size should be less than 10 MB!',
            value=>value.length<=3 || 'Total files that can be uploaded should be less than 3 files',
        ],
        attachments:null,
        remark:null,
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
            success:null,
        },
        clientOptions:["Irfan Hafiz","Azhar Ibrahim"],
        clientSelected:null,
        company:null,
        whatssapTemplateSelected:null,
        whatssapTemplate:"",
        whatssapTemplateOptions:[
            {
                name:"Welcome Message",
                value:'Dear xxx, welcome to the TaxPOD masterclass',
                
            },
            {
                name:"Survey Message",
                value:'Dear xxx, We are currently conduction a survey to help us understanding customer needs...',
            },
            {
                name:'Renewal Message',
                value:'Dear xxx, we notice that your renewal is almost expired in next month, we would like to hear more from you if you want to renew your subscriptions',
            },
        ],
    
    }),
    created() {
        this.api.callbackReset = () =>
        {
            this.api.isLoading = true;
            this.api.isError = false;
            this.api.isSuccesful = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isError = true;
            this.api.isLoading = false;
            this.api.isSuccesful = false;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if(resp.class=="getCompanyClient"){
                this.company = resp.data[0].company_name;
                let client = [];
                resp.data.forEach(data => {
                    let tempClient = {
                        'name':data.user_name,
                        'mobile':data.mobile,
                        'email':data.email,
                    };
                    client.push(tempClient);
                });
                this.clientOptions = client;
                
            }

            if(resp.class=="getAllWhatssapTemplate"){

                let allowedRoles = ["developer","operation"];
                let tempData = this.$_.clone(resp.data);
                if(!allowedRoles.includes(this.$store.getters.getRole)){
                    // if the roles is not developer or operation. need to filter the data and only allow public or private belong
                    let userId = this.$store.getters.getUserId;
                    let tempFilter = tempData.filter(function(item){
                    
                        if(item.accessibility==="public"){
                            return true;
                        }
                        if(parseInt(item.user_id)==parseInt(userId) && item.accessibility==="private"){

                            return true;
                        }
                    })
                    tempData = tempFilter;
                }

                let tempWhatssapTemplateOption = []
                tempData.forEach(template=>{
                    let tempTemplate = {
                        'name':template.whatssap_name,
                        'value':template.message,
                    }
                    tempWhatssapTemplateOption.push(tempTemplate);
                });
                this.whatssapTemplateOptions = tempWhatssapTemplateOption;
            }
          
            this.api.isError = false;
            this.api.isLoading = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchCompanyApi = this.fetchCompany();
            let fetchWhatssapTemplateApi = this.fetchWhatssapTemplate();
            this.$axios.all([
                this.$api.fetch(fetchCompanyApi),
                this.$api.fetch(fetchWhatssapTemplateApi),

            ])
        },
        fetchCompany(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/company/client/"+this.$router.history.current.params.id;
            return tempApi;
        },
        fetchWhatssapTemplate(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/whatssap/template";
            return tempApi;
        },
        redirectBack(){
            this.$router.go(-1);
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let createAttachmentApi;
            createAttachmentApi = this.create();
            this.$api.fetch(createAttachmentApi);
        },
        cancelSubmit(){
            this.isPending = false;
        },
        sendWhatssap(){
            this.api.isError = false;
            if(this.whatssapTemplate.length>2000){
                this.$refs.message.focus();
                this.api.isError = true;
                this.api.error = "Cannot send whatssap if message is more than 2000 characters"
                return false;
            }
            let tempClientSelected = this.$_.clone(this.clientSelected.mobile);
            if(tempClientSelected[0]==0 &&tempClientSelected[1]==1){
                tempClientSelected = "6"+String(tempClientSelected)
            }
            let url = "https://wa.me/+"+tempClientSelected+"?text="+this.whatssapTemplate;
            window.open(url);
        },
        create(){
           
        },
        updateWhatssapTemplate(){
            this.whatssapTemplate = this.$_.clone(this.whatssapTemplateSelected.value);
        },
    }
}
</script>